<template>
  <div>
    <h3>Audience Import</h3>
    <div class="bordered">
      <el-form class="form">
        <el-row :gutter="20">
          <el-col :md="12">
            <el-form-item label="Audience Name">
              <el-input v-model="title" />
            </el-form-item>
            <el-form-item label="Organization">
              <org-picker :organization.sync="organization" />
            </el-form-item>
            <el-form-item>
              <tag-picker :tags.sync="tags" />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <div class="csv-alert">
              <el-alert
                show-icon
                title="File is assumed to not have headers."
                type="info"
              />
            </div>
            <uploader
              ref="uploader"
              single-file
              api-endpoint="/api/v1/audiences/import/devices"
              upload-field-name="imports"
              @complete="handleUploadComplete"
              @file-change="handleFileChange"
              @upload-state-change="handleStateChange"
            >
              Drag CSV file here
            </uploader>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="!formReady"
            @click="doImport"
          >
            Import Audience
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isUTF8 } from '../../../helpers';
import OrgPicker from '../../global/OrgPicker.vue';
import TagPicker from '../../global/TagPicker.vue';
import Uploader from '../../global/Uploader.vue';

export default {
  components: {
    OrgPicker,
    TagPicker,
    Uploader,
  },

  data() {
    return {
      busy: false,
      credentials: {},
      fbForm: {
        permissions: 'Targeting',
      },
      files: [],
      loading: false,
      organization: {},
      organizations: [],
      partner: '',
      tags: [],
      title: '',
    };
  },

  computed: {
    ...mapGetters('user', ['orgInfo']),

    formReady() {
      return (
        !this.loading &&
        this.title &&
        this.organization.id &&
        this.files.length > 0
      );
    },
  },

  methods: {
    async handleFileChange(fileList) {
      this.loading = true;

      const utf8 = await isUTF8(fileList[0]);
      if (!utf8) {
        this.$refs.uploader.reset();
        this.$notify.error({
          message: 'File is not UTF-8 encoded.',
        });
        this.loading = false;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const body = reader.result;
        const firstLine = body.split('\n').shift();
        const columns = firstLine.split(',').length;
        if (columns !== 1) {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'Invalid file detected. Please ensure your file is a single column csv of MAIDs only.',
          });
        } else {
          this.files = fileList;
        }
        this.loading = false;
      };

      reader.readAsText(fileList[0], 'UTF-8');
    },

    handleStateChange(busy) {
      this.loading = busy;
    },

    handleUploadComplete(data) {
      this.resetForm();
      this.loading = false;

      if (data.status === 'success') {
        this.$notify.success({
          message: `Audience "${data.data.name}" has been imported with a count of ${data.data.count}.`,
        });
      } else {
        this.$notify.error({
          message: 'There was a problem importing the data.',
        });
      }
    },

    async doImport() {
      this.loading = true;

      try {
        if (this.files.length > 0) {
          // uploadedUrls = await this.uploadFiles();
          // const result = await s3Exists.callPromise({ urls: uploadedUrls });
          await this.$refs.uploader.startUpload({
            name: this.title,
            type: 'DEVICE_IMPORT',
            organization_id: this.organization.id,
            tags: JSON.stringify(this.tags),
          });
          window.mixpanel.track('Import Audience', {
            Name: this.title,
            Organization: this.organization.name,
            Tags: this.tags,
          });
        }
      } catch (e) {
        this.resetForm();
        if (e.error === 'Title & s3 Url must be unique.') {
          return this.$notify.error({
            message: e.error,
            duration: 7500,
          });
        } else if (e.error === 'publish-not-configured') {
          return this.$notify.error({
            message: e.reason,
            duration: 7500,
          });
        }

        this.$reportError(e);
        this.$notify.error({
          message: e.error,
          duration: 7500,
        });
      }
    },

    resetForm() {
      this.$refs.uploader.reset();
      this.loading = false;
      this.organization = this.$store.state.user.orgDetails;
      this.title = '';
      this.tags = [];
      this.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 14px;

  &.el-radio-button {
    display: inline-block !important;
  }

  i {
    font-size: 0.75rem;
  }
}

.bordered {
  margin: 1rem 0;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.form-control {
  margin-bottom: 1rem;
  .el-input,
  .el-select {
    width: 100%;
  }
}

.help {
  font-size: 12px;
  color: #666;
}

.manual-instructions {
  font-size: 14px;
  line-height: 1.5;
}

.csv-alert {
  margin-bottom: 20px;
}
</style>
